import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center w-96 mx-auto" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.pagination && _ctx.pagination.page)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["fixed z-10 left-0 right-0 bottom-0 w-screen pb-2 border-white pt-2 border-t-2", [
      _ctx.isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-200 text-gray-900',
    ]])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.pagination.page.previous)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.route('previous'))),
                class: _normalizeClass(["cursor-pointer select-none block px-3 py-4 mx-1 border-gray-300 border-2 hover:border-blue-300", [
          _ctx.isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]])
              }, "← ", 2))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.first)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.route('first'))),
                class: _normalizeClass(["cursor-pointer select-none block px-3 py-4 mx-1 border-gray-300 border-2 hover:border-blue-300", [
          _ctx.isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]]),
                innerHTML: _ctx.pagination.page.first
              }, null, 10, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.current - 1 > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["block px-2 py-4 mx-1", [
          _ctx.isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]])
              }, " ... ", 2))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.current)
            ? (_openBlock(), _createElementBlock("a", {
                key: 3,
                class: _normalizeClass(["cursor-default select-none block px-2 py-4 mx-1 border-gray-400 border-2", [
          _ctx.isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]]),
                innerHTML: _ctx.pagination.page.current
              }, null, 10, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.last - _ctx.pagination.page.current > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 4,
                class: _normalizeClass(["block px-2 py-4 mx-1", [
          _ctx.isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]])
              }, " ... ", 2))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.last)
            ? (_openBlock(), _createElementBlock("a", {
                key: 5,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.route('last'))),
                class: _normalizeClass(["cursor-pointer select-none block px-3 py-4 mx-1 border-gray-300 border-2 hover:border-blue-300", [
          _ctx.isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]]),
                innerHTML: _ctx.pagination.page.last
              }, null, 10, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.next)
            ? (_openBlock(), _createElementBlock("a", {
                key: 6,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.route('next'))),
                class: _normalizeClass(["cursor-pointer select-none block px-3 py-4 mx-1 border-gray-300 border-2 hover:border-blue-300", [
          _ctx.isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]])
              }, "→ ", 2))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}