import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xl:container grid grid-cols-6 px-2 pt-12 md:px-8 md:pt-8" }
const _hoisted_2 = { class: "col-span-2 md:col-span-1" }
const _hoisted_3 = { class: "hidden md:block transform -rotate-90 w-64 border-b-2 border-yellow-500 text-right pr-2 mt-48 -ml-20" }
const _hoisted_4 = { class: "bg-yellow-500 px-12 pt-2 text-gray-100 rounded-t-lg font-bold text-l uppercase" }
const _hoisted_5 = { class: "col-span-4 px-8 pt-10 md:col-span-5 md:px-4 md:pt-0" }
const _hoisted_6 = { class: "flex w-full" }
const _hoisted_7 = { class: "flex-none hidden md:block mb-2" }
const _hoisted_8 = { class: "switch" }
const _hoisted_9 = ["checked"]
const _hoisted_10 = { class: "nav-menu" }
const _hoisted_11 = { class: "hidden md:block pt-16" }
const _hoisted_12 = { class: "md:hidden px-3 pt-12 pb-12" }
const _hoisted_13 = { class: "flex w-full" }
const _hoisted_14 = { class: "inline-block flex-grow text-right pt-4" }
const _hoisted_15 = { class: "inline-block h-5 border-l-2 px-4 border-gray-100" }
const _hoisted_16 = {
  class: "mr-2",
  href: "https://www.linkedin.com/in/prazian/"
}
const _hoisted_17 = {
  class: "mx-2",
  href: "https://github.com/prazian"
}
const _hoisted_18 = {
  class: "ml-2",
  href: "https://twitter.com/pooyan_razian"
}
const _hoisted_19 = { class: "select-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_cookie_modal = _resolveComponent("cookie-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["h-full w-full", [
      _ctx.isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-900',
    ]])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["py-8 lg:py-16 px-6 lg:px-24", [
        _ctx.isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-900',
      ]])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("div", {
              class: "h-28 w-28 rounded-full shadow-xl border-2 border-white",
              style: {"background-image":"url('/img/profile/pooyan-supercoder.png')","background-size":"130%","background-position":"center top"}
            }, null, -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentRouteName), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex-1 text-xl md:text-2xl font-extrabold mb-2", [_ctx.isDarkMode ? 'text-white' : 'text-black']])
              }, " Pooyan Razian ", 2),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDarkMode && _ctx.toggleDarkMode(...args))),
                    checked: !_ctx.isDarkMode
                  }, null, 40, _hoisted_9),
                  _createElementVNode("span", {
                    class: _normalizeClass(["slider round border-2", [
                    _ctx.isDarkMode
                      ? 'bg-gray-700 border-gray-600'
                      : 'bg-gray-300 border-gray-100',
                  ]])
                  }, [
                    (!_ctx.isDarkMode)
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 0,
                          icon: ['fa', 'moon'],
                          class: "ml-2 mt-2"
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isDarkMode)
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 1,
                          icon: ['fa', 'sun'],
                          class: "text-yellow-400 ml-8 mt-2"
                        }))
                      : _createCommentVNode("", true)
                  ], 2)
                ])
              ])
            ]),
            _createElementVNode("nav", _hoisted_10, [
              _createVNode(_component_router_link, {
                activeClass: "link-text-active",
                class: "link-text",
                to: "/"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("About")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                class: _normalizeClass([{
                'link-text-active': _ctx.$route.path.startsWith('/articles'),
              }, "link-text"]),
                to: "/articles"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Articles")
                ])),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_router_link, {
                activeClass: "link-text-active",
                class: "link-text",
                to: "/career"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Career")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                activeClass: "link-text-active",
                class: "link-text",
                to: "/certificates"
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Certificates")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                activeClass: "link-text-active",
                class: "link-text",
                to: "/contact"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Contact")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("main", _hoisted_11, [
              _createVNode(_component_router_view)
            ])
          ])
        ]),
        _createElementVNode("main", _hoisted_12, [
          _createVNode(_component_router_view)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["md:hidden fixed top-0 left-0 h-12 w-full border-b-2 border-white shadow-md", [_ctx.isDarkMode ? 'bg-gray-800' : 'bg-gray-200']])
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", {
              class: "inline-block flex-shrink-0 px-2 pt-1",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["burger-button-bar", [_ctx.isDarkMode ? 'bg-white' : 'bg-black']])
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(["burger-button-bar", [_ctx.isDarkMode ? 'bg-white' : 'bg-black']])
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(["burger-button-bar", [_ctx.isDarkMode ? 'bg-white' : 'bg-black']])
              }, null, 2)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("a", _hoisted_16, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fab', 'linkedin'] })
                ]),
                _createElementVNode("a", _hoisted_17, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fab', 'github'] })
                ]),
                _createElementVNode("a", _hoisted_18, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fab', 'twitter'] })
                ])
              ]),
              _createElementVNode("div", {
                class: "inline-block h-5 border-l-2 px-4 border-gray-100 text-sm cursor-pointer",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleDarkMode()))
              }, [
                (!_ctx.isDarkMode)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: ['fa', 'moon']
                    }))
                  : _createCommentVNode("", true),
                (_ctx.isDarkMode)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 1,
                      icon: ['fa', 'sun'],
                      class: "text-yellow-400"
                    }))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.menuOpen ? 'visible' : 'invisible -ml-72', "md:hidden z-30 fixed top-0 left-0 h-full bg-gray-800 transition-all duration-300 ease-out"])
        }, [
          _createElementVNode("nav", _hoisted_19, [
            _createElementVNode("div", {
              class: "",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("div", { class: "absolute bg-gray-700 rounded-full border-gray-500 border-2 p-4 top-0 right-0 cursor-pointer flex flex-col items-center text-white text-sm mt-6 -mr-6" }, [
                _createElementVNode("svg", {
                  class: "fill-current text-white",
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "18",
                  height: "18",
                  viewBox: "0 0 18 18"
                }, [
                  _createElementVNode("path", { d: "M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" })
                ])
              ], -1)
            ])),
            _createVNode(_component_router_link, {
              onClick: _ctx.toggleMenu,
              class: "block text-left align-middle py-8 px-14 border-b-2 border-gray-400 capitalize text-2xl text-gray-100 font-semibold no-underline hover:text-gray-200 tracking-wider",
              to: "/"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("About ")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_router_link, {
              onClick: _ctx.toggleMenu,
              class: "block text-left align-middle py-8 px-14 border-b-2 border-gray-400 capitalize text-2xl text-gray-100 font-semibold no-underline hover:text-gray-200 tracking-wider",
              to: "/articles"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Articles ")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_router_link, {
              onClick: _ctx.toggleMenu,
              class: "block text-left align-middle py-8 px-14 border-b-2 border-gray-400 capitalize text-2xl text-gray-100 font-semibold no-underline hover:text-gray-200 tracking-wider",
              to: "/career"
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("Career ")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_router_link, {
              onClick: _ctx.toggleMenu,
              class: "block text-left align-middle py-8 px-14 border-b-2 border-gray-400 capitalize text-2xl text-gray-100 font-semibold no-underline hover:text-gray-200 tracking-wider",
              to: "/certificates"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Certificates ")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_router_link, {
              onClick: _ctx.toggleMenu,
              class: "block text-left align-middle py-8 px-14 border-b-2 border-gray-400 capitalize text-2xl text-gray-100 font-semibold no-underline hover:text-gray-200 tracking-wider",
              to: "/contact"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Contact ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ], 2)
      ], 2)
    ], 2),
    _createVNode(_component_cookie_modal),
    _createElementVNode("div", {
      class: _normalizeClass([[
      _ctx.isDarkMode ? 'bg-gray-800 md:bg-gray-800' : 'bg-gray-200 md:bg-gray-800',
    ], "md:hidden fixed left-0 right-0 bottom-0 w-screen border-white py-7 z-0 border-t-2"])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass([[_ctx.isDarkMode ? 'text-gray-100' : 'text-gray-900'], "flex flex-wrap pl-8 uppercase text-xs"])
      }, " Copyright © " + _toDisplayString(_ctx.currentYear) + " - pooyan.info ", 3)
    ], 2)
  ], 64))
}