import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_career_list_item = _resolveComponent("career-list-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-2xl font-bold mb-4" }, "Career", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.works, (work) => {
      return (_openBlock(), _createElementBlock("div", {
        key: work.id
      }, [
        _createVNode(_component_career_list_item, { work: work }, null, 8, ["work"])
      ]))
    }), 128)),
    _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-2xl font-bold mb-4" }, "Volunteering", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.volunteeringActivities, (volunteeringActivity) => {
      return (_openBlock(), _createElementBlock("div", {
        key: volunteeringActivity.id
      }, [
        _createVNode(_component_career_list_item, { work: volunteeringActivity }, null, 8, ["work"])
      ]))
    }), 128))
  ], 64))
}