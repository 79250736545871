import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex-initial mx-8 sm:mx-5 md:mx-4 mb-5 w-28 h-32 align-middle items-center" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "w-28 whitespace-nowrap link text-xs font-semibold uppercase" }
const _hoisted_5 = {
  key: 0,
  class: "w-28 whitespace-nowrap link text-xs font-semibold uppercase no-underline text-gray-400 dark:text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "inline-block align-middle text-center items-center",
      href: _ctx.project.url,
      target: "_blank"
    }, [
      _createElementVNode("img", {
        class: "h-24 w-24 rounded-full mb-3 shadow-md opacity-80 mx-auto hover:opacity-100 transition-opacity",
        src: _ctx.project.image
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.project.name), 1),
      (_ctx.project.isDeprecated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, " deprecated "))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}