import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "leading-loose md:text-justify list-inside" }
const _hoisted_2 = { class: "text-sm md:text-md font-semibold" }
const _hoisted_3 = { class: "text-sm text-gray-500 font-nunito-sans" }
const _hoisted_4 = {
  key: 0,
  class: "hidden md:inline mx-2 font-bold text-xs"
}
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.certificate.title), 1),
    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "hidden md:inline mx-2 font-bold text-xs" }, "-", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("br", { class: "md:hidden" }, null, -1)),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.certificate.issuer), 1),
    (_ctx.certificate.url)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
      : _createCommentVNode("", true),
    (_ctx.certificate.url)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.certificate.url,
          target: "_blank",
          class: "hidden md:inline hover:text-gray-400 no-underline"
        }, "verify", 8, _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}