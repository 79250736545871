import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "tag-search-text",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeTag(_ctx.tag)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.tag) + " ", 1),
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "tag-search-bg" }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "tag-search-x" }, "x", -1))
  ]))
}