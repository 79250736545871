import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "rounded-r-lg w-32 bg-gray-300 h-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type ? _ctx.skill.type === _ctx.type : true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass(["font-bold text-sm", [_ctx.isDarkMode ? 'text-gray-200' : 'text-gray-700']])
        }, _toDisplayString(_ctx.skill.name), 3),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["rounded-r-lg h-full", _ctx.getClass])
          }, " ", 2)
        ])
      ]))
    : _createCommentVNode("", true)
}