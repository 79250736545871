import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "leading-8 md:leading-6 text-justify mb-6 font-nunito-sans",
  typeof: "Person"
}
const _hoisted_2 = {
  class: "mt-4",
  itemprop: "description"
}
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "flex flex-row mb-4 flex-wrap" }
const _hoisted_5 = { class: "flex flex-row mb-4 flex-wrap" }
const _hoisted_6 = { class: "flex flex-row mb-4 flex-wrap" }
const _hoisted_7 = { class: "grid mb-4 flex-wrap space-x-4 md:grid-cols-2 2xl:grid-cols-3" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["alt", "src"]
const _hoisted_10 = { class: "w-fit flex flex-col justify-center truncate" }
const _hoisted_11 = ["href", "innerHTML"]
const _hoisted_12 = { class: "mx-auto block text-xs font-semibold uppercase text-gray-700 dark:text-gray-300" }
const _hoisted_13 = { class: "mx-auto block text-xs font-semibold uppercase text-gray-700 dark:text-gray-300" }
const _hoisted_14 = { class: "list-disc list-outside text-sm" }
const _hoisted_15 = { class: "pl-0 pb-4 md:pb-2 md:ml-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_hobby_project_item = _resolveComponent("hobby-project-item")!
  const _component_skill_bar_item = _resolveComponent("skill-bar-item")!
  const _component_recommendation = _resolveComponent("recommendation")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "text-2xl font-bold mb-4" }, "Who am I?", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _cache[0] || (_cache[0] = _createTextVNode(" An enthusiastic hands-on Solution Architect and a full-stack software developer with many years of experience with AWS, Linux, Cloud, Containers, etc. I support OSS, and I love ")),
        _createVNode(_component_font_awesome_icon, {
          class: "text-red-800 text-base mx-1",
          icon: ['fa', 'heart']
        }),
        _cache[1] || (_cache[1] = _createTextVNode(" Linux. "))
      ]),
      _createElementVNode("p", _hoisted_3, [
        _cache[2] || (_cache[2] = _createTextVNode(" During my career, I have built highly scalable APIs, automated quality checks and deployment (CI/CD pipelines), and scalable, reliable, and secure systems on cloud ")),
        _createVNode(_component_font_awesome_icon, {
          class: "text-green-600 text-base",
          icon: ['fa', 'cloud']
        }),
        _cache[3] || (_cache[3] = _createTextVNode(" infrastructures, mainly AWS. I have worked as a freelancer, consultant, and employee for various companies, including small startups, scale-ups, and large enterprise corporations. IT security is one of my side habits. "))
      ]),
      _cache[4] || (_cache[4] = _createStaticVNode("<p class=\"mt-4\"> No one knows everything, so I am constantly learning from different sources and trying new things. </p><p class=\"mt-4\"> Fun facts about me: <ul class=\"list-disc list-inside pt-2\"><li class=\"pl-2 pt-2\"> I have worked in data centers! </li><li class=\"pl-2 pt-2\"> I have won a security CTF hackathon at work </li></ul></p><p class=\"mt-4\"> I believe in freedom of speech, equality, diversity, and inclusion, and I actively promote them. I think every human should have an equal opportunity to learn and be successful, no matter what color, race, gender, disability, religion, look, or country we are born in. I believe the only thing that can differentiate us is our actions. I also think people and every living being should be respected, but we should allow thoughts and beliefs to be openly challenged. <span class=\"text-base md:text-sm\">🕊️</span></p>", 3))
    ]),
    _cache[12] || (_cache[12] = _createElementVNode("h2", { class: "text-xl font-bold mb-5 mt-6" }, "Projects (owned by me)", -1)),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
        return (_openBlock(), _createBlock(_component_hobby_project_item, {
          key: project.name,
          project: project
        }, null, 8, ["project"]))
      }), 128))
    ]),
    _cache[13] || (_cache[13] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-6" }, "Professional skills", -1)),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills, (skill) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex-initial mx-2 mb-2",
          key: skill.name
        }, [
          _createVNode(_component_skill_bar_item, { skill: skill }, null, 8, ["skill"])
        ]))
      }), 128))
    ]),
    _cache[14] || (_cache[14] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-6" }, "Languages", -1)),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex-initial mx-2 mb-2",
          key: language.name
        }, [
          _createVNode(_component_skill_bar_item, { skill: language }, null, 8, ["skill"])
        ]))
      }), 128))
    ]),
    _cache[15] || (_cache[15] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-6" }, "Public Talks", -1)),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.talks, (talk) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex-initial mb-2",
          key: talk.title
        }, [
          _createElementVNode("a", {
            href: talk.url,
            target: "_blank"
          }, [
            _createElementVNode("img", {
              class: "rounded-md mb-3 shadow-md opacity-80 mx-auto hover:opacity-100 transition-opacity cursor-pointer w-full",
              alt: talk.title,
              src: talk.image
            }, null, 8, _hoisted_9)
          ], 8, _hoisted_8),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("a", {
              class: "mx-auto whitespace-nowrap link text-xs font-semibold uppercase",
              href: talk.url,
              target: "_blank",
              innerHTML: talk.title
            }, null, 8, _hoisted_11),
            _createElementVNode("span", _hoisted_12, _toDisplayString(talk.event), 1),
            _createElementVNode("span", _hoisted_13, _toDisplayString(talk.month) + " " + _toDisplayString(talk.year), 1)
          ])
        ]))
      }), 128))
    ]),
    _cache[16] || (_cache[16] = _createElementVNode("h2", { class: "text-xl font-bold mb-6 mt-8" }, "What people say about me?", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommendations, (recommendation) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "mb-8 ml-2 md:ml-4",
        key: recommendation.id
      }, [
        _createVNode(_component_recommendation, { recommendation: recommendation }, null, 8, ["recommendation"])
      ]))
    }), 128)),
    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "mt-4 mb-8 ml-2 md:ml-4 mx-auto" }, [
      _createElementVNode("img", {
        class: "mx-auto rounded-md",
        src: "/img/linkedin-recommendations.png",
        alt: "Recommendations on linkedIn"
      })
    ], -1)),
    _cache[18] || (_cache[18] = _createElementVNode("h2", { class: "text-2xl font-bold mb-4" }, "Disclaimer", -1)),
    _createElementVNode("ul", _hoisted_14, [
      _cache[10] || (_cache[10] = _createStaticVNode("<li class=\"pl-0 pb-4 md:pb-2 md:ml-8\"> While every care has been taken to ensure the accuracy of the content of this website, I make no representation as to the accuracy, correctness or fitness for any purpose of the site content, nor I accept any liability for loss or damage (including consequential loss or damage), however caused, which may be incurred by any person or organisation from reliance on or use of information on this site. </li><li class=\"pl-0 pb-4 md:pb-2 md:ml-8\"> The contents of this website should not be construed as legal advice. </li><li class=\"pl-0 pb-4 md:pb-2 md:ml-8\"> Opinions are my own and not the views of my employer. </li><li class=\"pl-0 pb-4 md:pb-2 md:ml-8\"> I am not a political expert or a historian. That means, even though I might share my views based on the three decades of my personal life under the dictatorship in Iran, they might not be 100% accurate. </li><li class=\"pl-0 pb-4 md:pb-2 md:ml-8\"> English is not my mother-tongue language, so even though I try my best to express myself correctly, there might be chance of miscommunication. </li><li class=\"pl-0 pb-4 md:pb-2 md:ml-8\"> Links or references to other websites, including the use of information from 3rd-parties, are provided for the benefit of people who use this website. I am not responsible for the accuracy of the content on the websites that I have put a link to and I do not endorse any of those organizations or their contents. </li>", 6)),
      _createElementVNode("li", _hoisted_15, [
        _cache[6] || (_cache[6] = _createTextVNode(" If you have any queries or if you believe any information on this website and the replica of its content on my profiles on other websites, including but not limited to the content on ")),
        _cache[7] || (_cache[7] = _createElementVNode("a", {
          class: "link",
          href: " https://medium.com/@pooyan_razian/",
          target: "_blank"
        }, "medium.com", -1)),
        _cache[8] || (_cache[8] = _createTextVNode(", is inaccurate, or if you think any of the assets used on this website are in violation of copyright, please ")),
        _createVNode(_component_router_link, {
          class: "link",
          to: "/contact"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("contact me")
          ])),
          _: 1
        }),
        _cache[9] || (_cache[9] = _createTextVNode(" and let me know. "))
      ])
    ])
  ], 64))
}