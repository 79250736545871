import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row mb-4 mt-6" }
const _hoisted_2 = { class: "flex-none pr-12 align-center justify-center" }
const _hoisted_3 = { class: "basis-1/2 shrink pl-4" }
const _hoisted_4 = { class: "my-4" }
const _hoisted_5 = { class: "my-4" }
const _hoisted_6 = { class: "my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contact_form = _resolveComponent("contact-form")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createStaticVNode("<h1 class=\"text-2xl font-bold mb-4\">Contact me</h1><ul class=\"list-disc list-outside pt-4\"><li class=\"pl-0 pb-4 md:ml-8\">Do you want to speak about AWS and cloud?</li><li class=\"pl-0 pb-4 md:ml-8\">Do you want to speak about programming?</li><li class=\"pl-0 pb-4 md:ml-8\">Your IT solutions need to scale?</li><li class=\"pl-0 pb-4 md:ml-8\"> Want to have a quick talk over a cup of coffee? </li></ul><p class=\"mt-4 pl-1 pr-12 lg:pr-4\"> I would be more than happy to receive your message. <br><br>You can contact me by filling out the form below or connect with me on social media. </p>", 3)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_contact_form)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", _hoisted_4, [
            _createVNode(_component_font_awesome_icon, { icon: ['fab', 'linkedin'] }),
            _cache[0] || (_cache[0] = _createElementVNode("a", {
              class: "ml-2",
              href: "https://www.linkedin.com/in/prazian/"
            }, "linkedin.com/in/prazian", -1))
          ]),
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, { icon: ['fab', 'github'] }),
            _cache[1] || (_cache[1] = _createElementVNode("a", {
              class: "ml-2",
              href: "https://github.com/prazian"
            }, "github.com/prazian", -1))
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_font_awesome_icon, { icon: ['fab', 'twitter'] }),
            _cache[2] || (_cache[2] = _createElementVNode("a", {
              class: "ml-2",
              href: "https://twitter.com/pooyan_razian"
            }, "twitter.com/pooyan_razian", -1))
          ])
        ])
      ])
    ])
  ], 64))
}