import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative pt-4 pb-6 p-4 text-sm italic border-l-4 border-gray-300 bg-white text-gray-800 quote" }
const _hoisted_2 = { class: "text-justify leading-8 md:leading-6" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "hidden md:inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("blockquote", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      class: "stylistic-quote-mark top-0 -ml-3 -mt-4 text-gray-300",
      "aria-hidden": "true"
    }, " “ ", -1)),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.recommendation.content), 1),
    _createElementVNode("cite", {
      class: _normalizeClass(["absolute mt-3 text-xs font-semibold px-1 py-1 rounded-md", [_ctx.isDarkMode ? 'text-gray-100 bg-gray-900' : 'text-gray-700']])
    }, [
      _createElementVNode("a", {
        href: _ctx.recommendation.url,
        class: "hover:bg-blue-500 hover:cursor-pointer"
      }, _toDisplayString(_ctx.recommendation.authorFirstName), 9, _hoisted_3),
      _createElementVNode("span", _hoisted_4, " - " + _toDisplayString(_ctx.recommendation.authorJobTitle), 1),
      _createTextVNode(" @ " + _toDisplayString(_ctx.recommendation.authorCompany), 1)
    ], 2),
    _cache[1] || (_cache[1] = _createElementVNode("div", {
      class: "stylistic-quote-mark right-0 fa-rotate-180 text-gray-300 -mt-2",
      "aria-hidden": "true"
    }, " “ ", -1))
  ]))
}