import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-4 mt-8 flex flex-wrap gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_certificate_badge_item = _resolveComponent("certificate-badge-item")!
  const _component_certificate_badge_with_score_item = _resolveComponent("certificate-badge-with-score-item")!
  const _component_certificate_list_item = _resolveComponent("certificate-list-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "text-2xl font-bold mb-4" }, "Certificates", -1)),
    _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-8" }, "- Badges:", -1)),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.awsSpecialtyBadges, (badge) => {
        return (_openBlock(), _createBlock(_component_certificate_badge_item, {
          key: badge.img,
          badge: badge
        }, null, 8, ["badge"]))
      }), 128)),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-break" }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.awsBadges, (badge) => {
        return (_openBlock(), _createBlock(_component_certificate_badge_item, {
          key: badge.img,
          badge: badge
        }, null, 8, ["badge"]))
      }), 128)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-break" }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.awsPartnerBadges, (badge) => {
        return (_openBlock(), _createBlock(_component_certificate_badge_item, {
          key: badge.img,
          badge: badge
        }, null, 8, ["badge"]))
      }), 128)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-break" }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.awsCerts, (badge) => {
        return (_openBlock(), _createBlock(_component_certificate_badge_item, {
          key: badge.img,
          badge: badge
        }, null, 8, ["badge"]))
      }), 128)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-break" }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.awsCourseCompletionCertificates, (badge) => {
        return (_openBlock(), _createBlock(_component_certificate_badge_item, {
          key: badge.img,
          badge: badge
        }, null, 8, ["badge"]))
      }), 128)),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex-break" }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.pluralSightScores, (badge) => {
        return (_openBlock(), _createBlock(_component_certificate_badge_with_score_item, {
          key: badge.img,
          badge: badge
        }, null, 8, ["badge"]))
      }), 128)),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex-break" }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.otherBadges, (badge) => {
        return (_openBlock(), _createBlock(_component_certificate_badge_item, {
          key: badge.img,
          badge: badge
        }, null, 8, ["badge"]))
      }), 128))
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-8" }, "- Cloud:", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certificates, (certificate) => {
      return (_openBlock(), _createElementBlock("ol", {
        key: certificate.title
      }, [
        (certificate.category === 'cloud')
          ? (_openBlock(), _createBlock(_component_certificate_list_item, {
              key: 0,
              certificate: certificate
            }, null, 8, ["certificate"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-8" }, "- Programming:", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certificates, (certificate) => {
      return (_openBlock(), _createElementBlock("ol", {
        key: certificate.title
      }, [
        (certificate.category === 'programming')
          ? (_openBlock(), _createBlock(_component_certificate_list_item, {
              key: 0,
              certificate: certificate
            }, null, 8, ["certificate"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-8" }, "- Security:", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certificates, (certificate) => {
      return (_openBlock(), _createElementBlock("ol", {
        key: certificate.title
      }, [
        (certificate.category === 'security')
          ? (_openBlock(), _createBlock(_component_certificate_list_item, {
              key: 0,
              certificate: certificate
            }, null, 8, ["certificate"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-8" }, "- Leadership:", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certificates, (certificate) => {
      return (_openBlock(), _createElementBlock("ol", {
        key: certificate.title
      }, [
        (certificate.category === 'leadership')
          ? (_openBlock(), _createBlock(_component_certificate_list_item, {
              key: 0,
              certificate: certificate
            }, null, 8, ["certificate"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _cache[12] || (_cache[12] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 mt-8" }, "- Old && expired certificates:", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certificates, (certificate) => {
      return (_openBlock(), _createElementBlock("ol", {
        key: certificate.title
      }, [
        (certificate.category === 'Expired')
          ? (_openBlock(), _createBlock(_component_certificate_list_item, {
              key: 0,
              certificate: certificate
            }, null, 8, ["certificate"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 64))
}