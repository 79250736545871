import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-none flex-no-wrap"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "flex text-gray-500 font-bold md:font-semibold text-sm md:text-base" }
const _hoisted_4 = { class: "flex-shrink-0 flex-grow font-nunito-sans" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex-shrink-0 font-mono text-sm text-gray-400" }
const _hoisted_7 = { class: "hidden md:inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.badge.img)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.badge.img)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.getImage,
              alt: _ctx.getAlt,
              class: _normalizeClass([_ctx.getSizeClasses, "rounded-md"])
            }, null, 10, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.badge.name) + " ", 1),
            (_ctx.badge.nameHiddenSmall)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "hidden md:inline-flex",
                  innerHTML: _ctx.badge.nameHiddenSmall
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.badge.score.value), 1),
            _createElementVNode("span", _hoisted_7, " /" + _toDisplayString(_ctx.badge.score.max), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}