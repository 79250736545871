import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex-none flex-no-wrap w-32 md:w-60 xl:w-72" }
const _hoisted_2 = { class: "flex flex-col items-center justify-center" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.getDownloadUrl)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.getDownloadUrl,
            target: "_blank"
          }, [
            (_ctx.badge.img)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.getImage,
                  alt: _ctx.getAlt,
                  class: _normalizeClass([_ctx.getSizeClasses, "rounded-md"])
                }, null, 10, _hoisted_4))
              : _createCommentVNode("", true)
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.badge.img && !_ctx.getDownloadUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.getImage,
            alt: _ctx.getAlt,
            class: _normalizeClass([_ctx.getSizeClasses, "rounded-md"])
          }, null, 10, _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.badge.name)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "text-center text-sm font-semibold text-gray-500 w-2/3 pt-2",
            innerHTML: _ctx.badge.name
          }, null, 8, _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ]))
}