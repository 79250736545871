import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed z-20 bottom-0 right-0 w-full md:w-112 h-80 sm:h-64 md:h-76 pl-6 pr-7 py-4 bg-gray-800 text-gray-100 border-t-2 border-gray-700 md:rounded-tl-md md:border-t-2 md:border-l-2 md:border-gray-400 text-xs text-justify"
}
const _hoisted_2 = { class: "pb-2 text-md tracking-widest font-semibold" }
const _hoisted_3 = { class: "flex pt-8 pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isModalShown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", {
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideModal()))
            }, _cache[4] || (_cache[4] = [
              _createTextVNode("["),
              _createElementVNode("span", { class: "text-lg hover:text-yellow-400" }, "x", -1),
              _createTextVNode("]")
            ]))
          ]),
          _cache[11] || (_cache[11] = _createTextVNode(" Using cookies and analyzing IP addresses allow us to deliver and improve our web content and to provide you with a better experience. That means this website uses cookies and collects IP address for the purposes mentioned in the ")),
          _createVNode(_component_router_link, {
            class: "link-for-dark-bg",
            target: "_blank",
            to: "/cookie"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("cookie policy")
            ])),
            _: 1
          }),
          _cache[12] || (_cache[12] = _createTextVNode(". ")),
          _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
          _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
          _cache[15] || (_cache[15] = _createTextVNode("By accepting this, I agree that this website may use cookies and my IP address to collect individual statistics and to provide me with personalized content, offers, and ads subject to the ")),
          _createVNode(_component_router_link, {
            class: "link-for-dark-bg",
            target: "_blank",
            to: "/privacy"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("privacy policies")
            ])),
            _: 1
          }),
          _cache[16] || (_cache[16] = _createTextVNode(" and the ")),
          _createVNode(_component_router_link, {
            class: "link-for-dark-bg",
            target: "_blank",
            to: "/terms"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("terms of service")
            ])),
            _: 1
          }),
          _cache[17] || (_cache[17] = _createTextVNode(". This website may also use ")),
          _createVNode(_component_router_link, {
            class: "link-for-dark-bg",
            target: "_blank",
            to: "/cookie"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("third-party services")
            ])),
            _: 1
          }),
          _cache[18] || (_cache[18] = _createTextVNode(" for this purpose. I am aware that I can revoke my consent at any time by either opening this modal again or visiting the ")),
          _createVNode(_component_router_link, {
            class: "link-for-dark-bg",
            to: "/opt-out"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("opt-out")
            ])),
            _: 1
          }),
          _cache[19] || (_cache[19] = _createTextVNode(" page. ")),
          _createElementVNode("form", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "cursor-pointer flex-grow-0 hover:text-yellow-400",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.acceptCookie()))
              }, " Yes, I agree. "),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex-auto" }, null, -1)),
              _createElementVNode("div", {
                class: "cursor-pointer flex-grow-0 hover:text-yellow-400",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rejectCookie()))
              }, " No, thanks! ")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModal())),
      class: _normalizeClass([[
      _ctx.isDarkMode ? 'bg-gray-800 md:bg-gray-800' : 'bg-gray-200 md:bg-gray-800',
    ], "fixed z-10 bottom-0 right-0 px-4 pt-4 pb-5 rounded-tl-sm border-gray-100 md:px-4 md:py-3 md:rounded-tl-md md:border-t-2 md:border-gray-400 border-l-2 cursor-pointer"])
    }, [
      _createVNode(_component_font_awesome_icon, {
        class: _normalizeClass([{ invisible: _ctx.isVisible }, "text-yellow-400 text-base text-4xl md:text-sm"]),
        icon: ['fa', 'cookie']
      }, null, 8, ["class"])
    ], 2)
  ], 64))
}