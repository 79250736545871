import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col-3 mb-8 md:mb-0" }
const _hoisted_2 = { class: "flex-shrink-0 w-14 md:w-16" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex-grow pr-2 pl-5 md:pl-8" }
const _hoisted_5 = { class: "inline text-xl font-bold" }
const _hoisted_6 = {
  key: 0,
  class: "hidden md:inline text-xl text-gray-400 px-1"
}
const _hoisted_7 = {
  key: 1,
  class: "hidden md:inline text-xs text-gray-400 font-bold uppercase"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "hidden md:block pl-4 flex-shrink-0 text-right" }
const _hoisted_10 = { class: "font-semibold text-sm text-gray-400" }
const _hoisted_11 = { class: "font-semibold text-sm text-gray-400" }
const _hoisted_12 = { class: "hidden md:block ml-24 mb-8" }
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          class: "rounded-md shadow-md",
          src: _ctx.work.logo
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", null, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.work.company), 1),
          (_ctx.work.contractType)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "-"))
            : _createCommentVNode("", true),
          (_ctx.work.contractType)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.work.contractType), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("p", {
          class: "text-md font-semibold capitalize",
          innerHTML: _ctx.work.position
        }, null, 8, _hoisted_8)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.work.duration), 1),
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.work.location), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("p", {
        class: "text-md pt-2 leading-relaxed leading-6 font-nunito-sans",
        innerHTML: _ctx.work.description
      }, null, 8, _hoisted_13)
    ])
  ], 64))
}