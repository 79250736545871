import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center md:justify-start" }
const _hoisted_2 = { class: "input-group relative flex flex-shrink-0 items-stretch w-full md:w-56 mb-4 rounded md:mr-3" }
const _hoisted_3 = { class: "absolute z-20 mt-9 px-10 md:px-0 md:mt-8 w-full" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "block relative z-10 md:flex md:flex-grow-0 flex-wrap py-1 px-0 text-gray-900 font-semibold text-xs leading-10 md:leading-snug md:gap-x-1 md:gap-y-2" }
const _hoisted_6 = { class: "leading-8 md:leading-6 text-justify mb-6 font-nunito-sans" }
const _hoisted_7 = { class: "flex w-full border-b-2 border-color-gray-400" }
const _hoisted_8 = { class: "flex-auto text-left font-bold" }
const _hoisted_9 = { class: "hidden md:inline flex-auto text-right text-sm font-semibold" }
const _hoisted_10 = { class: "pt-4 flex space-x-8 w-full" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "hidden xl:inline-flex w-3/12 flex-shrink-0"
}
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag_item = _resolveComponent("tag-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_pagination_component = _resolveComponent("pagination-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-2xl font-bold" }, "Articles", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-2 border-t-2 border-white pr-1 hidden md:block" }, [
      _createElementVNode("span", { class: "text-sm font-bold float-right text-gray-500 dark:text-gray-300 pt-4 lg:pt-1" }, [
        _createTextVNode(" medium.com: "),
        _createElementVNode("a", {
          target: "_blank",
          class: "hover:text-gray-600 dark:hover:text-gray-400",
          href: "https://medium.com/@pooyan_razian"
        }, " @pooyan_razian ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "search",
          class: "form-control relative flex-auto min-w-0 block w-full pl-3 pr-8 py-2 text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none",
          placeholder: "Search by tag...",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchAvailableTags(_ctx.searchText)))
        }, null, 544), [
          [_vModelText, _ctx.searchText]
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("svg", {
          "aria-hidden": "true",
          focusable: "false",
          "data-prefix": "fas",
          "data-icon": "search",
          class: "w-9 absolute right-0 py-2 px-2 text-gray-300",
          role: "img",
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 512 512"
        }, [
          _createElementVNode("path", {
            fill: "currentColor",
            d: "M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
          })
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchedTags, (searchedTag, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            onClick: ($event: any) => (_ctx.addTag(searchedTag)),
            class: "flex flex-shrink-0 w-full z-20 py-4 px-12 md:w-56 md:py-2 md:pl-2 border-b border-gray-100 bg-gray-400 text-gray-900 font-semibold text-xs cursor-pointer hover:bg-blue-500"
          }, _toDisplayString(searchedTag), 9, _hoisted_4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
          return (_openBlock(), _createBlock(_component_tag_item, {
            key: index,
            tag: tag,
            onRemove: _ctx.removeTag
          }, null, 8, ["tag", "onRemove"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "pb-6"
        }, [
          _createVNode(_component_router_link, {
            to: '/articles/' + article.slug
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(article.title), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.dateOnly(article.publishedAt)), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: "w-7/12 flex-grow text-gray-700 dark:text-gray-400",
                  innerHTML: _ctx.summary(article.content)
                }, null, 8, _hoisted_11),
                (article.mainImage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("img", {
                        src: article.mainImage,
                        class: "w-full rounded-l-md opacity-90"
                      }, null, 8, _hoisted_13)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_pagination_component, {
      pagination: _ctx.pagination,
      onUpdated: _ctx.retrieve
    }, null, 8, ["pagination", "onUpdated"])
  ], 64))
}